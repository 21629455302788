import Vue from "vue";
import Vuex from "vuex";
import { user } from "@/utils/user";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isCollapse: false,
    CrumbsValue: "",
    CrumbsPath: "",
    isSwitchCrumbs: false,
    asideWidth: "200px",
    dropdownMenu: "none",
    userList: user, // 用户列表
    tableData: null, // 表格数据
    userTotal: null,
    loginInfo: {
      userName: "",
      userAvatar: "",
      userRole: "",
      isAdmin: false,
    },
  },
  getters: {
    totalUser: (state) => {
      return state.userList.length;
    },
  },
  mutations: {
    isCollapseClick(state, payload) {
      state.isCollapse = payload;
      if (payload) {
        state.asideWidth = "auto";
      } else {
        state.asideWidth = "200px";
      }
    },
    switchCrumbs(state, payload) {
      state.CrumbsValue = payload.value;
      state.CrumbsPath = payload.path;
      state.isSwitchCrumbs = true;
    },
    toggleDropdownMenu(state) {
      state.dropdownMenu = state.dropdownMenu === "none" ? "block" : "none";
    },

    // 用户操作
    SET_USER_LIST(state, userList) {
      state.userList = userList;
      state.tableData = userList;
    },
    ADD_USER(state, user) {
      state.userList.push(user);
    },
    UPDATE_USER(state, user) {
      const index = state.userList.findIndex((u) => u.id === user.id);
      const index2 = state.tableData.findIndex((u) => u.id === user.id);
      state.userList.splice(index, 1, user);
      state.tableData.splice(index2, 1, user);
    },
    DELETE_USER(state, userId) {
      const index = state.userList.findIndex((u) => u.id === userId);
      const index2 = state.tableData.findIndex((u) => u.id === userId);
      state.userList.splice(index, 1);
      state.tableData.splice(index2, 1);
    },
    SET_TABLE_DATA(state, tableData) {
      state.tableData = tableData;
    },
    setTableData(state, data) {
      state.tableData = data;
    },
    setTotalUser(state, total) {
      state.totalUser = total;
    },
    setLoginInfo(state, loginInfo) {
      state.loginInfo.userName = loginInfo.userName;
      state.loginInfo.userAvatar = loginInfo.userAvatar;
      state.loginInfo.userRole = loginInfo.userRole;
      if (loginInfo.userRole === "admin") {
        state.loginInfo.isAdmin = true;
      } else {
        state.loginInfo.isAdmin = false;
      }
    },
  },
  actions: {
    setUserList(context, userList) {
      context.commit("SET_USER_LIST", userList);
    },
    addUser(context, user) {
      context.commit("ADD_USER", user);
    },
    updateUser(context, user) {
      context.commit("UPDATE_USER", user);
    },
    deleteUser(context, userId) {
      context.commit("DELETE_USER", userId);
    },
    handlePaginationChange(context, { currentPage, pageSize, userTotal }) {
      // 获取当前页的数据并更新tableData
      console.log(currentPage, pageSize);
      const start = (currentPage - 1) * pageSize;
      const end = start + pageSize;
      const tableData = context.state.userList.slice(start, end);
      context.commit("SET_TABLE_DATA", tableData);
    },
    searchUser(context, searchText) {
      const filteredData = context.state.userList.filter((item) => {
        // 判断姓名、地址、性别是否包含搜索关键字
        return (
          (item.name && item.name.includes(searchText)) ||
          (item.address && item.address.includes(searchText)) ||
          (item.gender && item.gender.includes(searchText))
        );
      });
      context.commit("setTableData", filteredData);
      // context.commit("setTotalUser", filteredData.length);
      // context.commit("setCurrentPage", 1);
    },
  },
  plugins: [
    createPersistedState({
      key: "userList",
      paths: ["userList"],
      storage: window.localStorage,
    }),
    createPersistedState({
      key: "loginInfo",
      paths: ["loginInfo"],
      storage: window.localStorage,
    }),
  ],
  modules: {},
});
