<template>
  <div id="app">
    <router-view />

    <ul
      class="el-dropdown-menu el-popper"
      id="dropdown-menu-4885"
      x-placement="bottom-end"
      :style="{
        display: dropdownMenu,
      }"
    >
      <li
        tabindex="-1"
        class="el-dropdown-menu__item"
        @click="
          goHome();
          toggleDropdownMenu();
        "
      >
        个人中心
      </li>
      <li
        class="el-dropdown-menu__item"
        @click="
          logout();
          toggleDropdownMenu();
        "
      >
        退出登录
      </li>
      <div class="popper__arrow"></div>
    </ul>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {};
  },

  computed: {
    ...mapState(["dropdownMenu"]),
    ...mapMutations(["toggleDropdownMenu"]),
  },
  methods: {
    goHome() {
      this.$router.push("/homePage");
    },
    logout() {
      localStorage.removeItem("token");
      // delete this.$store.state.loginInfo;
      // delete this.$store.state.userList;
      this.$router.replace("/login");
    },
  },
};
</script>
<style lang="less" scoped>
#dropdown-menu-4885 {
  transform-origin: center top;
  z-index: 2002;
  position: absolute;
  top: 51px;
  left: 1400px;
  .popper__arrow {
    left: 71px;
  }
}
</style>
