import { Loading } from "element-ui";
import axios from "axios";
class PCRequest {
  constructor(baseURL, timeout = 10000) {
    this.instance = axios.create({
      baseURL,
      timeout,
    });

    //   请求拦截器
    this.instance.interceptors.request.use(
      (config) => {
        console.log("请求拦截器", config);
        // 开启Loading加载
        this.loadingInstance = Loading.service();
        return config;
      },
      (err) => {
        console.log("请求失败拦截");
        // 关闭Loading加载
        this.loadingInstance.close();
        return err;
      }
    );
    // 响应拦截器
    this.instance.interceptors.response.use(
      (res) => {
        console.log("响应拦截器", res);
        // 关闭Loading加载
        this.loadingInstance.close();
        return res;
      },
      (err) => {
        console.log("响应失败拦截器", err);
        // 关闭Loading加载
        this.loadingInstance.close();
        return err;
      }
    );
  }

  request(config) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get(config) {
    return this.request({ ...config, method: "get" });
  }

  post(config) {
    return this.request({ ...config, method: "post" });
  }
}

export default new PCRequest("http://124.222.220.114:8101");
