import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import pcRequest from "../service";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    redirect: "/homePage",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/UserManagement.vue"),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "/homePage",
        name: "homePage",
        component: () => import("@/views/HomePage.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/shop",
        name: "shop",
        component: () => import("@/views/ShopView.vue"),
        meta: {
          title: "商品",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    meta: {
      title: "注册",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 路由导航守卫
//判断用户是否登录
// Vue Router导航守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "My App";
  const isAuthenticated = getToken(); // 检查用户是否已登录
  if (to.path === "/login" || to.path === "/register") {
    if (isAuthenticated) {
      next("/homePage"); // 如果已经登录，重定向到主页
    } else {
      next(); // 否则允许继续访问
    }
  } else {
    if (!isAuthenticated) {
      next("/login"); // 如果未登录，重定向到登录页
    } else {
      checkTokenExpired();
      next(); // 否则允许继续访问
    }
  }
});

// 获取令牌
function getToken() {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return null;
}

// 检查令牌是否过期
function checkTokenExpired() {
  const tokenStr = localStorage.getItem("token");
  if (tokenStr) {
    const token = JSON.parse(tokenStr);
    if (Date.now() > token.expiredAt) {
      // 如果令牌已过期，则从localStorage中删除令牌，并重定向到登录页
      localStorage.removeItem("token");
      router.push("/login");
      axios.post("http://124.222.220.114:8101/api/user/logout");
    }
  }
}
export default router;
